@charset "UTF-8";
/*
ユーティリティ系おまとめファイル
*/
/*
██████   █████  ███████ ███████
██   ██ ██   ██ ██      ██
██████  ███████ ███████ █████
██   ██ ██   ██      ██ ██
██████  ██   ██ ███████ ███████
*/
/*
██████  ██████  ███████  █████  ██   ██ ██████   ██████  ██ ███    ██ ████████
██   ██ ██   ██ ██      ██   ██ ██  ██  ██   ██ ██    ██ ██ ████   ██    ██
██████  ██████  █████   ███████ █████   ██████  ██    ██ ██ ██ ██  ██    ██
██   ██ ██   ██ ██      ██   ██ ██  ██  ██      ██    ██ ██ ██  ██ ██    ██
██████  ██   ██ ███████ ██   ██ ██   ██ ██       ██████  ██ ██   ████    ██
*/
/* ██████  ██████  ██       ██████  ██████ ██      ██    ██ ██      ██    ██ ██   ██ ██      ██    ██ ██      ██    ██ ██████ ██      ██    ██ ██      ██    ██ ██   ██ ██████  ██████  ███████  ██████  ██   ██ */
/* ████████ ███████ ██   ██ ████████ ██    ██       ██ ██     ██ ██    █████     ███      ██ ██    ██       ██ ██     ██ ██    ███████ ██   ██    ██ */
.u-taL { text-align: left !important; }

.u-taC { text-align: center !important; }

@media screen and (max-width: 568px) { .u-taC--xs-taL { text-align: left !important; } }

@media screen and (max-width: 768px) { .u-taC--sm-taL { text-align: left !important; } }

@media screen and (max-width: 1024px) { .u-taC--md-taL { text-align: left !important; } }

@media screen and (max-width: 1280px) { .u-taC--lg-taL { text-align: left !important; } }

.u-taR { text-align: right !important; }

.u-only-xs { display: none !important; }

@media screen and (max-width: 568px) { .u-only-xs { display: block !important; } }

.u-only-sm { display: none !important; }

@media screen and (max-width: 768px) { .u-only-sm { display: block !important; } }

@media screen and (max-width: 568px) { .u-only-sm { display: none !important; } }

.u-only-md { display: none !important; }

@media screen and (max-width: 1024px) { .u-only-md { display: block !important; } }

@media screen and (max-width: 768px) { .u-only-md { display: none !important; } }

@media screen and (max-width: 1024px) { .u-only-lg { display: none !important; } }

.u-under-sm { display: none !important; }

@media screen and (max-width: 768px) { .u-under-sm { display: block !important; } }

.u-not-under-sm { display: block !important; }

@media screen and (max-width: 768px) { .u-not-under-sm { display: none !important; } }

.u-overflow-hidden { overflow: hidden !important; }

#top { margin-top: 0; }

#top .p-kv { position: relative; }

@media screen and (max-width: 568px) { #top .p-kv { height: 100vh; } }

#top .p-kv__catch { position: absolute; top: 30%; left: 50%; transform: translateX(-50%); max-width: 915px; padding: 0 20px; z-index: 1; }

@media screen and (max-width: 568px) { #top .p-kv__catch { top: 50%; transform: translateX(-50%) translateY(-50%); } }

#top .p-kvVideo { width: 100%; height: 100vh; position: relative; }

@media screen and (max-width: 568px) { #top .p-kvVideo { height: calc(100vh - 233px); } }

#top .p-kvVideo video { object-fit: cover; font-family: "object-fit: cover; object-position: center;"; object-position: center; width: 100%; height: 100%; }

#top .p-kvVideo::after { content: ''; position: absolute; left: 0; top: 0; height: 100%; width: 100%; background: #000; opacity: 35%; }

#top .p-kvVideo::after .is-IE { content: none; }

#top .p-kvImages { position: relative; max-width: 1170px; margin: -340px auto 0; z-index: 1; }

@media screen and (max-width: 1680px) { #top .p-kvImages { margin-top: -300px; } }

@media screen and (max-width: 1200px) { #top .p-kvImages { padding: 0 30px 90px; } }

@media screen and (max-width: 768px) { #top .p-kvImages { padding: 0 20px 90px; } }

@media screen and (max-width: 568px) { #top .p-kvImages { padding: 0; margin-top: -48px; margin-bottom: 60px; } }

#top .p-kvImages__item { height: 395px; }

@media screen and (max-width: 768px) { #top .p-kvImages__item { height: 300px; } }

@media screen and (max-width: 568px) { #top .p-kvImages__item { height: 233px; } }

#top .p-kvImages__item picture { height: 100%; width: 100%; }

#top .p-kvImages__item img { object-fit: cover; font-family: "object-fit: cover; object-position: center;"; object-position: center; width: 100%; height: 100%; }

#top .p-kvImages__copy { color: #D00022; text-align: center; margin-bottom: 15px; position: relative; font-family: "Roboto", sans-serif; font-style: italic; font-weight: 900; display: flex; align-items: center; justify-content: center; }

@media screen and (max-width: 568px) { #top .p-kvImages__copy { font-size: 20px; } }

@media screen and (max-width: 568px) { #top .p-kvImages__copy { padding: 0 10px; } }

#top .p-kvImages__copy::before { content: ''; background-color: #D00022; height: 1px; width: 100%; }

#top .p-kvImages__copy::after { content: ''; background-color: #D00022; height: 1px; width: 100%; }

#top .p-kvImages__copy span { flex-shrink: 0; padding: 0 15px; }

@media screen and (max-width: 568px) { #top .p-kvImages__copy span { padding: 0 8px; } }

#top #wrapper { padding: 0 0; }

@media screen and (max-width: 568px) { #top #wrapper { margin-top: 60px; } }

#top .p-delimiter { width: 100%; background-repeat: no-repeat; background-position: center; background-size: cover; }

@media screen and (max-width: 1200px) { #top .p-delimiter { margin-bottom: 60px; } }

@media screen and (max-width: 768px) { #top .p-delimiter { height: 260px; } }

#top .p-delimiter--top { background-image: url("/inc/image/top/bg_top_delimiter.jpg"); background-position: right 10% bottom 30%; height: 565px; margin-bottom: 30px; }

@media screen and (max-width: 1200px) { #top .p-delimiter--top { margin-bottom: 60px; height: 400px; } }

@media screen and (max-width: 768px) { #top .p-delimiter--top { height: 260px; } }

#top .p-delimiter--bottom { background-image: url("/inc/image/top/bg_bottom_delimiter.jpg"); height: 380px; }

@media screen and (max-width: 1200px) { #top .p-delimiter--bottom { height: 300px; } }

#top .p-group__title { margin-bottom: 35px; }

@media screen and (max-width: 1280px) { #top .p-group__title { margin-bottom: 60px; } }

@media screen and (max-width: 768px) { #top .p-group__title { margin-bottom: 30px; } }

#top .p-group__inner { display: flex; justify-content: space-between; align-items: center; }

@media screen and (max-width: 1024px) { #top .p-group__inner { flex-direction: column; } }

@media screen and (max-width: 768px) { #top .p-group__inner { flex-direction: column; } }

#top .p-group__inner > li { margin: 0 0; }

@media screen and (max-width: 768px) { #top .p-group__inner > li { margin: 0; } }

#top .p-group__text { max-width: 600px; flex-grow: 1; padding-right: 25px; }

@media screen and (max-width: 1280px) { #top .p-group__text { padding-right: 0; } }

@media screen and (max-width: 1024px) { #top .p-group__text { max-width: 100%; margin-bottom: 30px !important; } }

#top .p-group__list-item { margin-bottom: 35px; }

#top .p-group__list-item:last-child { margin-bottom: 0; }

#top .p-group__image { padding-left: 25px; height: auto; flex-shrink: 0; max-width: 440px; }

@media screen and (max-width: 1280px) { #top .p-group__image { padding-left: 0; } }

@media screen and (max-width: 768px) { #top .p-group__image { max-width: 350px; } }

#top .p-business__box { margin-bottom: -10px; margin-left: -5px; margin-right: -5px; }

@media screen and (max-width: 768px) { #top .p-business__box { margin: 0 -15px -30px; } }

#top .p-business__box > [class*="col-"] { padding: 0 5px 10px; }

@media screen and (max-width: 768px) { #top .p-business__box > [class*="col-"] { padding: 0 15px 30px; } }

@media screen and (max-width: 1200px) { #top .p-business__box { margin-left: -10px; margin-right: -10px; } }

#top .p-business__box > li { position: relative; }

#top .p-business__box > li:nth-child(2) { top: 60px; }

@media screen and (max-width: 1024px) { #top .p-business__box > li:nth-child(2) { top: 0; } }

#top .p-business__box > li:nth-child(3) { top: 120px; }

@media screen and (max-width: 1024px) { #top .p-business__box > li:nth-child(3) { top: 0; } }

#top .p-business__wrapper { max-width: 1920px; padding: 0 10px 180px; margin: 0 auto; }

@media screen and (max-width: 1280px) { #top .p-business__wrapper { padding-bottom: 150px; } }

@media screen and (max-width: 1024px) { #top .p-business__wrapper { padding: 0 20px 120px; } }

@media screen and (max-width: 768px) { #top .p-business__wrapper { padding-bottom: 90px; } }

#top .p-business__title { max-width: 1170px; margin: 0 auto 60px; }

@media screen and (max-width: 1280px) { #top .p-business__title { margin-left: 10px; } }

@media screen and (max-width: 768px) { #top .p-business__title { margin: 0 auto 30px; } }

#top .p-News { margin-bottom: 30px; }

@media screen and (max-width: 1200px) { #top .p-News { margin-bottom: 0; } }

#top #newslist { position: relative; }

@media screen and (max-width: 1024px) { #top #newslist { display: flex; flex-direction: column; } }

#top #newslist .c-btn { position: absolute; margin: 0; top: 60px; right: 0; }

@media screen and (max-width: 1200px) { #top #newslist .c-btn { top: 0; right: 30px; } }

@media screen and (max-width: 1024px) { #top #newslist .c-btn { position: relative; top: 0; right: 0; order: 3; margin-top: 30px; } }

#top #company { padding-bottom: 30px; }

@media screen and (max-width: 568px) { #top #company { padding-bottom: 0; } }

#top #company .p-company { position: relative; }

#top #company .p-companyArea { max-width: 870px; margin: 0 auto; }

@media screen and (max-width: 1200px) { #top #company .p-companyArea { max-width: 100%; } }

#top #company .p-companyArea__list li { margin-bottom: 5px; }

@media screen and (max-width: 568px) { #top #company .p-companyArea__list { padding-top: 0; } }

#top #company .p-companyAboutImg { max-width: 270px; height: auto; position: absolute; top: -30px; right: 0; }

@media screen and (max-width: 1200px) { #top #company .p-companyAboutImg { display: none; } }
